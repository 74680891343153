import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CategoriesInfoType } from '../../types';

interface DropdownProps {
  categories: CategoriesInfoType,

}
function HeaderDropdown(props: DropdownProps) {
  const [num, setNum] = useState(5)

  const handleClick = (param: number) => {
    setNum(param)
  }
  return (
    <div className='dropDown'>
      {props.categories.map((el, index) => {
        return (
          <div className='box' key={index} onMouseLeave={() => setNum(5)}>

            <div className={`wrapper ${index}`} onMouseEnter={() => handleClick(index)} >
              <img src={el.imageUrl} alt="404" />
              <h3>{el.title}</h3>
            </div>

            <div className={`inner ${index}`} style={{ display: num === index ? 'flex' : "none" }} >
              {el.subCategories.map((item) => {
                return (
                  <Link to={`/category/${item.slug}`} key={item.id}>{item.title}</Link>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default HeaderDropdown