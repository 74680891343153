import "./_style.scss";
import {ProductDetailInfoType} from "../../../types";
import {useContext, useState} from "react";
import {AuthContext, InstallmentModalContext} from "../../../App";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {formatPrice, isFavourite, isInBasket, isInCompare} from "../../../helpers";
import {addToBasket} from "../../../features/basket/basketSlice";
import {useT} from "../../../custom/hooks/useT";
import {addToFavoutires, removeFromFavourites} from "../../../features/favourites/favouritesSlice";
import {addToCompare} from "../../../features/Compares/comparesSlice";
import Payments from "../Payments";



function ProductViewRightInfo(props: ProductDetailInfoType){

    const { id, name, brandName, slug, price, old_price, imageUrl, description, characterAssigns, category_id, is_treaty, code, short_description } = props;
    const [isOpenBuyNowModal, setIsOpenBuyNowModal] = useState<boolean>(false);
    const [isDescription, setDescription]=useState<boolean>(false);
    const onOpenBuyNowModal = () => setIsOpenBuyNowModal(true);
    const onCloseBuyNowModal = () => setIsOpenBuyNowModal(false);
    const { setIsOpenInstallmentModal } = useContext(InstallmentModalContext);

    const dispatch = useAppDispatch();
    const auth = useAppSelector((store) => store.auth);
    const { data: favourites } = useAppSelector((state) => state.favourites);
    const { products } = useAppSelector((state) => state.basket);
    const { compares } = useAppSelector((state) => state.compares);
    let isFavorite = isFavourite(favourites, id);
    let isThereInBasket = isInBasket(products, id);
    let isThereCompare = isInCompare(compares, category_id, id);
    const { onOpenSignInModal } = useContext(AuthContext);


    let product = {
        id,
        name,
        brandName,
        slug,
        price,
        old_price,
        imageUrl,
        category_id,
        is_treaty,
    }


    const { t, lang } = useT();




    const onFavouriteClick = () => {
        if (auth.authorized) {
            if (isFavorite) {
                dispatch(removeFromFavourites(product));
            } else {
                dispatch(addToFavoutires(product));
            }
        } else {
            onOpenSignInModal();
        }
    };



    const getStringBasket =()=>{
        let find =
            products.find(value =>{return value.id===id});
        return !!find;
    }




    const handleAddBasket = () => {
        dispatch(addToBasket({ ...product, count: 1 }))
    }
    const handleAddCompare = () => {
        dispatch(addToCompare({ category_id, id, name }))
    }

    const getDiscount=()=>{
        return Math.ceil(100-(price/old_price*100));
    }



    return (
        <div className={"rightContent"}>
            {(old_price!==0)&&
            <div style={{display:"flex", gap:"18px"}}>
                <p style={{textDecorationLine:"line-through", fontSize:"17px"}}>{formatPrice(11000000)} {t(`sum.${lang}`)}</p>
                <div style={{background:"linear-gradient(90deg, #ED3729 0%, #FF6F43 101.69%)",
                    borderRadius:"5px", color:"white", fontSize:"16px", width:"51px", height:"26px", textAlign:"center", lineHeight:"26px"}}>
                    -{getDiscount}%
                </div>

            </div>}

            <p className={"title28_bold"}>{formatPrice(price)} {t(`sum.${lang}`)}</p>
            <button className={"addBasket"} onClick={handleAddBasket}>
                {!getStringBasket()?t(`addToCart.${lang}`):t(`removeToCart.${lang}`)}
            </button>

            <div style={{display:"flex", gap:"16px"}}>
                <button className={"buttunOther"} onClick={onFavouriteClick}>{isFavorite?t(`removeToFavourites.${lang}`):t(`addToFavourites.${lang}`)}</button>
                <button className={"buttunOther"} onClick={handleAddCompare}>{t(`balance.${lang}`)}</button>
            </div>

            <div style={{marginTop:"23px"}}>
                <Payments/>
            </div>
        </div>
    );
}

export default ProductViewRightInfo;