import { useState, useEffect, useCallback } from 'react'
import { paymentListUrl } from '../../../api/apiUrls';
import baseAPI from '../../../api/baseAPI';
import { useT } from '../../../custom/hooks/useT';
import {
  PaymentListResType,
  PaymentListResTypeInfo, PaymentListResTypeInfoTypes,
} from '../../../types';
import "./_style.scss";
import {Row} from "antd";
import {type} from "os";


function Payments() {

  const { t, lang } = useT();
  const [paymentList, setPaymentList] = useState<PaymentListResTypeInfoTypes[]>();


  const getPaymentList = useCallback(() => {
    baseAPI.fetchAll<PaymentListResType>(paymentListUrl)
      .then((res) => {
        if (res.data.status === 200) {
          setPaymentList(res.data.data);
        }
      })
  }, []);



  const getString=(type:string)=>{
    var arr = new Array();
    paymentList?.map(({title, type: type1}, index, array)=>{
      if (type1===type){
        arr.push(title);
      }
    });

    return arr.join(", ");
  }


  const countPaymentTypePaymentList=(type:string)=>{
    let count=0;
    paymentList?.map(({title, type: type1}, index, array)=>{
      if (type1===type){
        count++;
      }
    });
    return count;
  }




  useEffect(() => {
    getPaymentList();
  }, [getPaymentList])

  return (
    <div className="product_view_right_info">
      {/* payment */}
      <div className="payment product_view_right_info_card">
        <div className="payment">
          < div className="product_view_right_info_card_body">
            <Row style={{gap:"20px"}}>
              <img src={"/assets/icons/delivery.svg"}/>
              <p className={"boldP"}>Бесплатная доставка</p>
            </Row>

            <Row style={{gap:"20px"}}>
              <img src="/assets/icons/payment.svg" alt="payment" width={"24px"} />
              <p className={"boldP"}>Способы оплаты:</p>
            </Row>


            {countPaymentTypePaymentList("type_cash")>1&&
                <Row style={{gap:"8px"}}>
                  {getString("type_cash")}
                </Row>}

            {countPaymentTypePaymentList("type_cash")===1&&
                <Row style={{gap:"8px"}}>
                  <p className={"boldP"}>Наличными:  </p>
                  <p>При доставке</p>
                </Row>}


            <Row style={{gap:"8px"}}>
              <p className={"boldP"}>Виды оплаты: </p>
              {getString("payment_types")}
            </Row>

            <Row>
              <p className={"boldP"}>Типы кредитных карт:</p>
              {getString("cart_types")}
            </Row>

            {countPaymentTypePaymentList("type_listing_with_nds")===1&&
                <Row style={{marginTop:"10px"}}>
                  <p>Перечислением с учётом НДС</p>
                </Row>}

            {countPaymentTypePaymentList("type_listing_with_nds")>1&&
                <Row style={{marginTop:"10px"}}>
                  {getString("type_listing_with_nds")}
                </Row>}



          </div>
        </div>
      </div>
    </div >
  )
}

export default Payments;