import { configureStore } from '@reduxjs/toolkit'
import authSlice from '../features/authSlice'
import basketSlice from '../features/basket/basketSlice'
import comparesSlice from '../features/Compares/comparesSlice'
import favouritesSlice from '../features/favourites/favouritesSlice'
import loadingSlice from '../features/loading/loadingSlice'

export const store = configureStore({
	reducer: {
		auth: authSlice,
		loading: loadingSlice,
		favourites: favouritesSlice,
		compares: comparesSlice,
		basket: basketSlice,
	},
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch