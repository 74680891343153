import "./_style.scss";
import {Input, notification, Rate, Row} from 'antd';
import React, {useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import BuyButton from "../BuyButton";
import {useT} from "../../../../custom/hooks/useT";
import {BASE_URL} from "../../../../constants";
import {CommentGetType, ProductDetailInfoType} from "../../../../types";
import axios from "axios";
import baseAPI from "../../../../api/baseAPI";
import { Pagination } from 'antd';
import {AddCommitNotif} from "../../../../components/Notifications";

const desc = {uz:['Juda yomon', 'Yomon', 'O`rtacha', 'Yaxshi', 'Juda yaxshi'],
              ru:['Очень плохо', 'Плохо', 'Средне', 'Хорошо', 'Очень хорошо']};



function Comment (props:ProductDetailInfoType){
    const { id } = props;


    const [name, setName]=React.useState<string>("");
    const [isName, setIsName]=React.useState<boolean>(false);
    const [description, setDescription]=React.useState<string>("");
    const [isDescription, setIsDescription]=React.useState<boolean>(false);
    const [rate, setRate]=React.useState<number>(0);
    const [isRate, setIsRate]=React.useState<boolean>(false);

    const { t, lang } = useT();

    const [page, setPage]=useState<number>(1);
    const [comments, setComments]=useState<CommentGetType[]>();
    const [totalComment, setTotalComment]=useState<number>(0);

    const getComments=async ()=>{
        const res=await axios.get(BASE_URL+"/product-manager/product/product-comments?id="+id+"&page="+page);
        setComments(res.data.data.items);
        setTotalComment(res.data.data._meta.totalCount);
    }




    useEffect(()=>{
        getComments();
    }, [page]);
    useEffect(()=>{
        getComments();
    }, []);

    async function submit() {

        if (name.length<1){
            setIsName(true);
        }else {
            setIsName(false);
        }
        if (description.length<1){
            setIsDescription(true);
        }else {
            setIsDescription(false);
        }
        if (rate<=0 || rate>5){
            setIsRate(true);
        }else {
            setIsRate(false);
        }

        if (name.length>0 && description.length>0 && rate>0) {
            setIsRate(false);
            setIsDescription(false);
            setIsName(false);

            let data={"product_id":id, "comment":description, "value":rate, "name":name}

            let res = await baseAPI.create(BASE_URL+"/product-manager/product/create-product-comment",data );
            // @ts-ignore
            if (res.data.status===200){
                setName("");
                setRate(0);
                setDescription("");
            }
            AddCommitNotif();

        }


    }

    return (
        <div className={"comment"}>
            <Row style={{width:"100%"}}>

                {comments?comments.map((value, index) => {
                    return (
                        <Row key={value.id} style={{width:"100%", border:"1px solid red", margin:"12px 0px", borderRadius:"8px"}}>
                            <Row className={"titleComment"}>
                                <p>{value.name}</p>
                                <p>{value.date}</p>
                            </Row>

                            <Row className={"mainContent"} >
                                <div>
                                    <p>{value.comment}</p>
                                    <Rate disabled defaultValue={value.value}/>
                                </div>
                            </Row>



                        </Row>
                    );
                }):<p>Comment mavjud emas</p>}

                {totalComment>0&&
                    <Pagination className={"panigationCostum"} current={page} total={totalComment} onChange={(e)=>{setPage(e)}} />
                }




            </Row>



            <form>
                <h1>{t(`writeARecommendation.${lang}`)}</h1>

                <Row>
                    <p><span style={{color:"red"}}>*</span><span>{t(`yourName.${lang}`)}:</span></p>
                    <Input value={name} onChange={(e)=>setName(e.target.value)}/>
                    {isName&&<p style={{color:"red"}}>{t(`yourName*.${lang}`)}</p>}
                </Row>

                <Row>
                    <p><span style={{color:"red"}}>*</span><span>{t(`yourRecommendation.${lang}`)}:</span></p>
                    <TextArea rows={6} value={description} onChange={(e)=>setDescription(e.target.value)}/>
                    {isDescription&&<p style={{color:"red"}}>{t(`yourRecommendation*.${lang}`)}</p>}
                </Row>

                <Row align={"middle"}>
                    <p><span style={{color:"red"}}>*</span><span>{t(`rate.${lang}`)}:</span></p>
                    <Rate value={rate} tooltips={lang==="uz"?desc.uz:desc.ru} onChange={setRate}/>
                    {isRate&&<p style={{color:'red'}}>{t(`choose.${lang}`)}</p>}

                </Row>

                <BuyButton text={t(`sendComment.${lang}`)} onClick={submit}></BuyButton>

            </form>
        </div>
    );
}


export default Comment;