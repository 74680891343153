import React from 'react';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import { useT } from "../../../custom/hooks/useT";
import {ProductDetailInfoType} from "../../../types";
import "./_style.scss";
import ProductInfoCompGrid from "../ProductDescription/ProductInfoCompGrid";
import Comment from "../ProductDescription/Comment";


const ProductTabs= (props:ProductDetailInfoType) => {
    const { id, name, brandName, slug, price, old_price, imageUrl, description, characterAssigns, category_id, is_treaty, code, short_description } = props;


    const { t, lang } = useT();

    return (
            <Tabs defaultActiveKey="1" style={{width:"100%"}} id={"show"}>
            <Tabs.TabPane tab={t(`technicalSpecifications.${lang}`)} key="1">
                {
                    characterAssigns?.length !== 0 ? (
                        <ProductInfoCompGrid description={characterAssigns} />
                    ): <p>{t(`notFoundResource.${lang}`)}</p>
                }
            </Tabs.TabPane>
            <Tabs.TabPane tab={t(`allAboutTheProduct.${lang}`)} key="2">
                {
                    short_description ? (
                        <div className="product_desc__area">
                            <p className="title20_bold product_desc__area__title">
                                {t(`desc.${lang}`)}
                            </p>
                            <p className="p14_regular product_desc__area__content" dangerouslySetInnerHTML={{ __html: short_description}} />
                        </div>
                    ) : <p>{t(`notFoundResource.${lang}`)}</p>
                }
            </Tabs.TabPane>
            <Tabs.TabPane tab={t(`customerReviews.${lang}`)} key="3">
                <Comment {...props}/>
            </Tabs.TabPane>
        </Tabs>
    );
};

export default ProductTabs;