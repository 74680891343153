import React from 'react'
import { CharacterAssignsType } from '../../../../types';
import "./_style.scss";
import {useT} from "../../../../custom/hooks/useT";

interface IProductInfoComp {
  description: CharacterAssignsType[]
}

function ProductInfoComp(props: IProductInfoComp) {

  const { description } = props;
    const { t, lang } = useT();


    return (
    <div className="product_info_comp" style={{width:"100%"}}>
      <h3 className="title24_bold product_info_title" style={{margin:0}}>
          {t(`technicalSpecifications.${lang}`)}
      </h3>
      {
        description?.map((descrip, index) => {
          if (index>=5){
            return;
          }
          return (
              <div className="product_info_body" key={descrip.id}>
                <p className="desc_name">
                  {descrip.characterName}
                </p>
                <p className="desc">
                  {descrip.value}
                </p>
              </div>
          )
        })
      }

      <a href={"#show"}>{t(`allFeatures.${lang}`)}</a>
    </div>
  )
}

export default ProductInfoComp