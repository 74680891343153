export const brandsUrl = "site/brands/";
export const bannersUrl = "site/banners/";
export const stockUrl = "site/ads/";
export const headerSettingsUrl = "site/header-settings/";
export const bestsellerUrl = "product-manager/product/bestseller/";
export const newProductsUrl = "product-manager/product/newcomers/";
export const recommendedProductsUrl = "product-manager/product/recommended/";
export const promotionsUrl = "product-manager/product/stocks/";
export const categoriesUrl = "product-manager/category/index/";
export const recommendedCategoriesUrl = "product-manager/category/recommended-categories/";
export const byCategoriesProductUrl = "product-manager/product/category";
export const searchUrl = "product-manager/product/search";
export const menuCategoriesUrl = "product-manager/category/menu-categories";
export const leftMenuUrl = "page-manager/page/pages/";
export const oneLeftMenuUrl = "page-manager/page/detail";
export const headerTopMenuUrl = "site/header-menus/";
export const footerDataUrl = "site/socials/";
export const footerMenuUrl = "site/footer-menu/";
export const footerSettingsUrl = "site/footer-settings/";
export const productDetailUrl = "product-manager/product/detail"
export const returnExchangeUrl = "site/exchange-return/";
export const paymentListUrl = "payment-manager/payment-types/index/";
export const enterPhoneUrl = "auth/register/phone/";
export const verifyCodeUrl = "auth/register/verify/";
export const registerUrl = "auth/register/register/";
export const profileUrl = "profile-manager/profile/index/";
export const profileUpdateUrl = "profile-manager/profile/update";
export const loginUrl = "auth/auth/login/";
export const resetEnterPhoneUrl = "auth/reset/phone/";
export const resetVerifyCodeUrl = "auth/reset/verify/";
export const resetPasswordUrl = "auth/reset/reset-password/";
export const moreProductsUrl = "product-manager/product/";
export const newCommersProductsUrl = "product-manager/product/new-comers-view";
export const getFavouritesUrl = "product-manager/product-save/index";
export const addFavoritesUrl = "product-manager/product-save/create";
export const deleteAllFavoritesUrl = "product-manager/product-save/delete-all";
export const buyNowUrl = "site/buy-now";
export const haveQuestionsUrl = "site/have-question/";
export const feedBackUrl = "site/contact/";
export const contactTypesUrl = "site/contact-types/";
export const orderPaymentTypesUrl = "order-manager/order/payment-types/";
export const regionsUrl = "order-manager/order/regions";
export const districtsUrl = "order-manager/order/districts";
export const orderUrl = "order-manager/order/by";
export const myOrderUrl = "profile-manager/order/my-order";
export const comparesUrl = "profile-manager/balance/index/";
export const b2bUrl = "site/b2b-contact/";
export const alifShopUrl = "v2/alif/alifs/";
export const intendUrl = "v2/intend/calc/";
export const alifUserCheckUrl = "v2/alif/check-buyer";
export const alifValCodeUrl = "v2/alif/request-otp/";
export const alifOrderUrl = "v2/alif/credit-verify/";