
import { Link } from 'react-router-dom';

interface IPopularSliderCard {
  id: number,
  title: string,
  slug: string,
  imageUrl: string
}

function PopularSliderCard(props: IPopularSliderCard) {
  const { title, imageUrl, slug } = props;
  return (
    <div className="card">
      <Link to={`/category/${slug}`} className="popular__slider__card">
        <div className="img_card">
          <img
            className="popular__slider__card__img"
            src={imageUrl} alt={title}
          />
        </div>
        <div className="card_title">

        </div>
        <h5
          className="title20_bold popular_card_title"
        >
          {title}
        </h5>
      </Link>
    </div>
  )
}

export default PopularSliderCard