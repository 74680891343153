import { useContext } from 'react';
import { Card, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useT } from '../../custom/hooks/useT';
import { ProductType } from '../../types';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { addToFavoutires, removeFromFavourites } from '../../features/favourites/favouritesSlice';
import { AuthContext } from '../../App';
import { formatPrice, isFavourite, isInBasket, isInCompare } from '../../helpers';
import { addToBasket } from '../../features/basket/basketSlice';
import "./_style.scss";
import { addToCompare } from '../../features/Compares/comparesSlice';

interface IProductCard {
  product: ProductType
}

function ProductCard(props: IProductCard) {
  const {
    name,
    slug,
    old_price,
    price,
    imageUrl,
    id,
    category_id,
    is_treaty
    // userSaveProduct: isFavourite
  } = props.product;


  const dispatch = useAppDispatch();
  const auth = useAppSelector((store) => store.auth);
  const { data: favourites } = useAppSelector((state) => state.favourites);
  const { products } = useAppSelector((state) => state.basket);
  const { compares } = useAppSelector((state) => state.compares);
  let isFavorite = isFavourite(favourites, id);
  let isThereInBasket = isInBasket(products, id);
  let isThereCompare = isInCompare(compares, category_id, id);
  const { onOpenSignInModal } = useContext(AuthContext);
  const { t, lang } = useT();

  const onFavouriteClick = () => {
    if (auth.authorized) {
      if (isFavorite) {
        dispatch(removeFromFavourites(props.product));
      } else {
        dispatch(addToFavoutires(props.product));
      }
    } else {
      onOpenSignInModal();
    }
  };

  const handleAddBasket = () => {
    dispatch(addToBasket({ ...props.product, count: 1 }));
  }

  const handleAddCompare = () => {
    dispatch(addToCompare({ category_id, id, name }))
  }

  return (
    <Card className="product_card" bordered={false} hoverable>
      <div className="card_body" title={name}>
        <img src={`/assets/icons/heart-${isFavorite ? 'red' : 'gray'}.svg`} alt="heart" onClick={onFavouriteClick} className="favorite" />
        <Link className="product_view_link" to={`/product/detail/${slug}`}>
          <figure>
            <img src={imageUrl ?? ""} alt="watch" className="product_card_img" />

          </figure>
        </Link>

        <div className="product_info">

          <div className="prices" style={{display:"flex", width:"100%", flexDirection:"column", gap:0}}>

            <p className='oldprice' style={{alignSelf:"flex-end", color:"red"}}>
              {
                  old_price !== 0 && (
                      <>
                        {formatPrice(old_price)} {t(`sum.${lang}`)}
                      </>
                  )
              }</p>

            <p className="price title18_bold">

              {
                is_treaty !== 1 ? (
                  <>
                    {formatPrice(price)} {t(`sum.${lang}`)}
                  </>
                ) : t(`treaty.${lang}`)
              }
            </p>

          </div>


          <Link className='product_view_link' to={`/product/detail/${slug}`}>
            <h5 className="product_name">
              {name.substring(0, 50).concat("...")}
            </h5>
          </Link>
        </div>

      </div>
      <div className="card_footer">
        <button onClick={handleAddBasket}>{t(`buy.${lang}`)}</button>

      </div>


    </Card >
  )
}

export default ProductCard