import React from 'react'
import { CharacterAssignsType } from '../../../../types';
import "./_style.scss";
import { Col, Row } from 'antd';
import {useT} from "../../../../custom/hooks/useT";

interface IProductInfoComp {
  description: CharacterAssignsType[]
}

function ProductInfoCompGrid(props: IProductInfoComp) {

  const { description } = props;
    const { t, lang } = useT();






    return (
    <div className="product_info_comp" style={{width:"70%"}}>
      <h3 className="title24_bold product_info_title">
          {t(`technicalSpecifications.${lang}`)}
      </h3>
        <Row style={{gap:"10px"}}>
                {
                    description?.map(descrip => (
                        <Col span={11} xs={24} sm={24} md={11} lg={11} style={{borderBottom:"1px dashed black"}}>
                            <div className="product_info_body" key={descrip.id}>
                                <p className="desc_name">
                                    {descrip.characterName}
                                </p>
                                <p className="desc">
                                    {descrip.value}
                                </p>
                            </div>
                        </Col>
                    ))
                }

        </Row>

    </div>
  )
}

export default ProductInfoCompGrid;